[v-cloak] {
  display: none;
}


@font-face {
  font-family: 'level09';
  src: url(font/level09/level09-regular.otf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'l09thin';
  src: url(font/level09/level09-thin.otf);
}


@font-face {
  font-family: 'level09';
  src: url(font/level09/level09-italic.otf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'level09';
  src: url(font/level09/level09-bold.otf);
  font-style: normal;
}

@font-face {
  font-family: 'level09-black';
  src: url(font/level09/level09-black.otf);
  font-style: normal;
}

body {
  font-family: 'level09', sans-serif;
  color: #515151;

}


a {
  color: black;
}

a:hover {
  text-decoration: none;
  color: #888;
}

h1, h2, h3, h4 {
  font-weight: bold;
  color: #000;
}

p {
  font-size: 20px;

  font-family: 'l09thin', sans-serif;
}

#app {
  border-top: 4px solid #111;
  overflow: hidden;
}

.preloader {
  background: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-content: center;
  justify-content: center;

  img {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    width: 100px;
    margin: auto;
  }
}


nav.navbar {
  padding: 20px 40px;
  height: 88px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.logo {
  max-width: 70px;
}

.logo img {
  width: 100%;
}

.main-nav {
  display: flex;
}

.main-nav a {
  margin: 0 1rem;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;


}

.main-nav a::before,
.main-nav a::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: currentColor;
  top: 100%;
  left: 0;
  pointer-events: none;
}

.main-nav a::before {
  content: '';
  transform-origin: 100% 50%;
  transform: scale3d(0, 1, 1);
  transition: transform 0.3s;
}

.main-nav a:hover {
  color: currentColor;
}

.main-nav a:hover::before {
  transform-origin: 0% 50%;
  transform: scale3d(1, 1, 1);
}


.hero-wrap {

  height: calc(100vh - 88px);
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .hero-wrap {
    height: 60vh;
  }
}

.hero {
  margin-top: -5%;


}

.hero h2 {
  text-transform: uppercase;
  font-family: 'level09-black', sans-serif;
  font-weight: bold;
  color: #000;
  font-size: 5rem;
}


.hero p {
  font-size: 1.2rem;

  font-weight: 300;
  margin-bottom: 1.5rem;
}

.cta {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: 1px solid #282828;
  font-size: 1rem;
  border-radius: 2px;
  padding: 8px 20px;
  text-transform: uppercase;
  background: #282828;
  color: #fff;
  margin: 1rem auto;
}

.cta:hover {
  background: #000;
  text-decoration: none;
  color: #fff;
}

.scroll {
  margin-top: 1rem;
  display: block;
}

.brands {
  margin: 6rem 0;
}


.brands img {
  width: 140px;

  object-fit: contain;
  filter: saturate(0);


}

.brand {
  margin: 0.5rem;
}


section h3 {
  font-size: 3rem;
  margin-bottom: 2rem;
}


.intro {
  width: 100%;
  box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.1);

}

@media (max-width: 480px) {
  .intro {
    display: none;
  }

}


.intro video {
  width: 100%;
  height: auto;
}


.about {
  margin: 6rem 0;

}


.brands {
  margin: 6rem 0;
}

.brands .grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(200px, 1fr));
  grid-template-rows: repeat(2, 150px);
  align-items: center;
  justify-content: center;
}

.brands .grid img {
  margin: auto;

}

@media (max-width: 768px) {
  .brands .grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
}


/* contact */

$primary: #333;
$secondary: #aaa;
$white: #fff;
$gray: #9b9b9b;
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 3rem;
  width: 66%;
}

.form__field {
  font-family: 'l09thin', 'level09', sans-serif;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  font-size: 1.3rem;
  color: #202020;
  padding: 7px 0;

  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $gray;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $primary;

  }

  padding-bottom: 6px;

  border-width: 3px;
  border-image: linear-gradient(to right, $primary, $secondary);
  border-image-slice: 1;
}

/* reset input */
.form__field {
  &:required, &:invalid {
    box-shadow: none;

  }
}

.contact input[type=submit] {
  margin-top: 3rem;
  width: 200px;
  margin-left: auto;
}

@media (max-width: 768px) {
  .form__group, .contact input[type=submit] {
    width: 94%;
  }
}

.projects {
  padding: 3rem 0;
  position: relative;
}

.project h3 {
  margin-bottom: 5rem;
}

/* carousel */

#carousel {
}

.car-bg {
  position: absolute;
  top: -200px;
}


.carousel-cell {
  width: 1000px;
  height: 625px;
  margin: 0 2rem;
}

@media (max-width: 768px) {
  .carousel-cell {
    margin: 0 5px;
    width: 80%;
    height: 300px;
  }
  .carousel-cell img {
    object-fit: contain;
  }
}

.carousel-cell img, .carousel-cell video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.carousel-cell h4 {
  position: absolute;
  bottom: 10px;
  color: #fff;
  background: #000;
  padding: 1px 3px 1px 14px;

}

/*
.flickity-button {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 25%;
    transform: none;
    border-radius: 0;
    background-color: transparent;
}
.Carousel__items .flickity-button.previous {
    right: 100%;
    left: auto;
}
.Carousel__items .flickity-button.next {
    left: 100%;
    right: auto;
}

*/


footer {
  height: 100px;
  display: flex;
  align-content: center;
  justify-content: left;
  max-width: 1000px;
  width: 100%;
  padding: 3rem;
  margin: 1rem auto;


}

/* loading css animation */

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #ddd;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

p.status {
  font-size: 24px;
  color: #54ab57;
}


/*Responsive*/

@media (max-width: 769px) {
  nav.navbar {
    flex-direction: column;
    justify-content: center;
  }

  nav.navbar .main-nav {
    display: none;
  }

  .hero {
    padding: 1rem;
    margin-top: -20%;
  }

  .hero h2 {
    font-size: 3rem;
    margin-top: 1rem

  }


}